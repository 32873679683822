import {createStore} from 'vuex'
import {UserControllerService} from "../../generated";

export default createStore({
    state: {
        // 初始化用户信息状态
        userInfo: {
            id: null,
            userAccount: '',
            userName: '',
            userInfo:{
                id: null,
            }
            // 其他你想要存储的用户信息
        }
    },
    getters: {
        // 获取用户信息
        getUserInfo: state => {
            return state.userInfo
        },
        isLogin: state => {

            return !!state.userInfo.userInfo.id;
        }
    },
    mutations: {
        // 设置用户信息
        SET_USER_INFO(state, userInfo) {
            state.userInfo = userInfo
        },
        // 清除用户信息
        CLEAR_USER_INFO(state) {
            state.userInfo = {
                id: null,
                userAccount: '',
                userName: '',
                userInfo:{
                    id: null,
                }
                // 其他信息也要重置
            }
        }
    },
    actions: {
        // 异步操作，例如从服务器获取用户信息
        fetchUserInfo({commit}, userId) {
            // 模拟从API获取数据
            return new Promise((resolve, reject) => {
                // 假设这里是一个API调用
                setTimeout(() => {
                    const userInfo = {
                        id: userId,
                        name: '张三',
                        email: 'zhangsan@example.com',
                        // 其他信息
                    }
                    commit('SET_USER_INFO', userInfo)
                    resolve(userInfo)
                }, 1000)
            })
        },
        checkLogin({ commit, state }) {
            // 如果 userInfo 已经存在，则无需再次检查
            // if (state.userInfo.userInfo?.id !== null) {
            //     return Promise.resolve(true);
            // }
            // 调用 API 获取登录用户信息
            return UserControllerService.getLoginUserUsingGet().then(response => {
                if (response.code===0) {
                    // 如果 API 返回了用户信息，则设置用户信息
                    commit('SET_USER_INFO', response.data);
                    return true;
                }
                return false;
            }).catch(() => {
                // 如果发生错误或用户未登录，则清除用户信息
                commit('CLEAR_USER_INFO');
                return false;
            });
        },
        // 登录或注册后存储用户信息
        storeUserInfo({commit}, userInfo) {
            commit('SET_USER_INFO', userInfo)
        },
        // 登出时清除用户信息
        logout({commit}) {
            commit('CLEAR_USER_INFO')
        }
    },
    modules: {
        // 如果你的应用很大，你可以将状态管理分割到模块中
    }
})
