import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import InputDataView from '../views/InputDataView.vue'
import SsqView from '../views/SsqWx.vue'
import LoginView from '../views/LoginView.vue'
import zx3dView from '../views/ZhiXuan3DView.vue'
import store from '../store';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '3D五行',
    component: HomeView
  },

  {
    path: '/ssq',
    name: '双色球五行',
    component: SsqView
  },
  {
    path: '/zx3d',
    name: '直选3D',
    component: zx3dView
  },
  {
    path: '/about',
    name: '彩票号码',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/inputData',
    name: '每日输入',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: InputDataView
  },
  {
    path: '/AiChat',
    name: 'Ai小助手',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AIChatView.vue')
  },
  {
    path: '/login',
    name: '登录',
    component: LoginView,
    meta:{
        isShow: false,
    }
  }
  // {
  //   path: '/wxConfig',
  //   name: '五行配置',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/WxConfigView.vue')
  // },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  // 如果访问的不是登录页

  if (to.fullPath !== "/login") {
    // 检查用户是否登录
    store.dispatch('checkLogin').then(isLoggedIn => {
      if (!isLoggedIn) {
        // 如果用户未登录，则重定向到登录页
        next('/login');
      } else {
        // 如果用户已登录，则继续导航
        next();
      }
    }).catch(() => {
      // 如果发生错误，则也重定向到登录页
      next('/login');
    });
  } else {
    // 如果访问的是登录页，则继续导航
    next();
  }
});

export default router
