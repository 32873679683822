/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_List_NywxConfigScq_ } from '../models/BaseResponse_List_NywxConfigScq_';
import type { BaseResponse_List_string_ } from '../models/BaseResponse_List_string_';
import type { NyWxRequestDTO } from '../models/NyWxRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NyWxResultControllerService {

    /**
     * getList
     * @returns BaseResponse_List_NywxConfigScq_ OK
     * @throws ApiError
     */
    public static getListUsingGet(): CancelablePromise<BaseResponse_List_NywxConfigScq_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/nyWxResult',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * nyWxResult
     * @param nyWxRequestDto nyWxRequestDTO
     * @returns BaseResponse_List_string_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static nyWxResultUsingPost(
nyWxRequestDto: NyWxRequestDTO,
): CancelablePromise<BaseResponse_List_string_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/nyWxResult',
            body: nyWxRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }



    /**
     * computer
     * @param nyWxRequestDto nyWxRequestDTO
     * @returns BaseResponse_List_string_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static computerUsingPost(
        nyWxRequestDto: NyWxRequestDTO,
    ): CancelablePromise<BaseResponse_List_string_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/nyWxResult/computer',
            body: nyWxRequestDto,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
