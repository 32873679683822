<template>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
<!--  <router-view/>-->
  <div v-if="route.path==='/login'">
   <LoginLayOut></LoginLayOut>
  </div>
  <div v-else>
    <BaseLayOut></BaseLayOut>
  </div>

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<!--openapi --input http://localhost:8101/api/v2/api-docs --output ./generated --client axios
-->
<script setup lang="ts">
import BaseLayOut from "@/layout/BaseLayOut.vue";
import {useRoute} from "vue-router";
import LoginLayOut from "@/layout/LoginLayOut.vue";
const route = useRoute();

console.log("App路由",route)


</script>
