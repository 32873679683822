/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_Page_Wx_ } from '../models/BaseResponse_Page_Wx_';
import type { BaseResponse_Void_ } from '../models/BaseResponse_Void_';
import type { BaseResponse_Wx_ } from '../models/BaseResponse_Wx_';
import type { Wx } from '../models/Wx';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class Digits3DControllerService {

    public static getListPageUsingGet(object:any): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/digits3D/page',
            query: {
                'current': object.current,
                'endDate': object.endDate,
                'pageSize': object.pageSize,
                'sortField': object.sortField,
                'sortOrder': object.sortOrder,
                'startDate': object.startDate,
                'showNumber':object.showNumber,
                'isSave': object.isSave
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }



    /**
     * getList
     * @param current
     * @param endDate
     * @param id
     * @param name
     * @param pageSize
     * @param sortField
     * @param sortOrder
     * @param startDate
     * @param wxData
     * @returns BaseResponse_Page_Wx_ OK
     * @throws ApiError
     */
    public static updateWxName(body:any): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/digits3D/update',
            body: body,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }



}
