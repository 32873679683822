/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_List_ResultAll_ } from '../models/BaseResponse_List_ResultAll_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ResultControllerService {

    /**
     * getResult
     * @returns BaseResponse_List_ResultAll_ OK
     * @throws ApiError
     */
    public static getResultUsingGet(): CancelablePromise<BaseResponse_List_ResultAll_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/result',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getResultByType
     * @param type type
     * @returns BaseResponse_List_ResultAll_ OK
     * @throws ApiError
     */
    public static getResultByTypeUsingGet(
type: string,
): CancelablePromise<BaseResponse_List_ResultAll_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/result/{type}',
            path: {
                'type': type,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
