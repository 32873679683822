<script setup lang="ts">

</script>

<template>
  <el-divider />
  <el-row :gutter="20">
    <el-col :span="4"><div class="grid-content ep-bg-purple" /></el-col>
    <el-col :span="16">  <el-link type="success">©五行推算系统 by 石尊良</el-link><div class="grid-content ep-bg-purple" /></el-col>
    <el-col :span="4"><div class="grid-content ep-bg-purple" /></el-col>
  </el-row>
</template>

<style scoped>

</style>
