/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_Page_Wx_ } from '../models/BaseResponse_Page_Wx_';
import type { BaseResponse_Void_ } from '../models/BaseResponse_Void_';
import type { BaseResponse_Wx_ } from '../models/BaseResponse_Wx_';
import type { Wx } from '../models/Wx';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DsNameControllerService {

    /**
     * add
     * @param wx wx
     * @returns BaseResponse_Void_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addUsingPost(
        data: any,
    ): CancelablePromise< any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/dsName',
            body: data,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }



    /**
     * getList
     * @param current
     * @param endDate
     * @param id
     * @param name
     * @param pageSize
     * @param sortField
     * @param sortOrder
     * @param startDate
     * @param wxData
     * @returns BaseResponse_Page_Wx_ OK
     * @throws ApiError
     */
    public static getListUsingGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dsName',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    public static aiChat(data:string): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/aiChat/?msg='+data,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }


}
