<template>
  <div class="inputData">
    <span>福彩3D</span>
    <el-tabs type="border-card">
      <!--      -->
      <el-tab-pane label="常规">
        <el-form :model="form" label-width="auto" style="max-width: 600px">
          <el-form-item label="旺">
            <el-select v-model="form.value1" placeholder="选择“旺”的五行">
              <el-option :key="item.id" :label="item.name" :value="item.name" v-for="item in nywxConfig"/>
            </el-select>
          </el-form-item>
          <el-form-item label="相">
            <el-select v-model="form.value2" placeholder="选择“相”的五行">
              <el-option :key="item.id" :label="item.name" :value="item.name" v-for="item in nywxConfig"/>
            </el-select>
          </el-form-item>
          <el-form-item label="大师名字">
            <el-select v-model="form.dsName" placeholder="输入大师名字">
              <el-option :key="item.id" :label="item.name" :value="item.name" v-for="item in dsNameList"/>
            </el-select>
            <el-button type="warning" @click="addDsName"> 添加大师</el-button>
          </el-form-item>
          <el-form-item label="3D号码">
            <el-input v-model="form.result3D"></el-input>
          </el-form-item>
          <el-form-item label="双色球号码">
            <el-input v-model="form.resultSsq"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="onComputer">计算</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
  <el-dialog v-model="isShow" title="添加大师" width="500">
    <el-form :model="dsNameform">
      <el-form-item label="大师名字">
        <el-input v-model="dsNameform.name" autocomplete="off"/>
      </el-form-item>

    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="Cancel()">取消</el-button>
        <el-button type="primary" @click="submit()">
          提交
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup lang="ts">
import {onMounted, ref} from "vue";
import {
  DsNameControllerService,
  type NywxConfigScq,
  type NyWxRequestDTO,
  NyWxResultControllerService
} from "../../generated";
import {ElMessage} from "element-plus";

const form = ref<any>({});
const onComputer = async () => {
  if (form.value.dsName == null || form.value.dsName == "") {
    return ElMessage({
      type: "warning",
      message: "大师名字不能为空"
    })
  }
  if (form.value.value1 == null || form.value.value1 == "") {
    return ElMessage({
      type: "warning",
      message: "旺不能为空"
    })
  }
  if (form.value.value2 == null || form.value.value2 == "") {
    return ElMessage({
      type: "warning",
      message: "相不能为空"
    })
  }

  const request = {

    nyWxRequestDTOList: [
      {
        wan: "旺",
        wx: form.value.value1
      },
      {
        wan: "相",
        wx: form.value.value2
      },
      // {
      //   wan: "休",
      //   wx: form.value.value3
      // }
    ],
    dsName: form.value.dsName
  } as NyWxRequestDTO;

  const res = await NyWxResultControllerService.computerUsingPost(request)
  if (res.code == 0) {
    form.value.result3D = res.data.result3D;
    form.value.resultSsq = res.data.resultSsq;
    ElMessage({
      type: "success",
      message: "计算成功"
    })
  }
}
const onSubmit = async () => {
  if (form.value.dsName == null || form.value.dsName == "") {
    return ElMessage({
      type: "warning",
      message: "大师名字不能为空"
    })
  }
  if (form.value.value1 == null || form.value.value1 == "") {
    return ElMessage({
      type: "warning",
      message: "旺不能为空"
    })
  }
  if (form.value.value2 == null || form.value.value2 == "") {
    return ElMessage({
      type: "warning",
      message: "相不能为空"
    })
  }

  const request = {

    nyWxRequestDTOList: [
      {
        wan: "旺",
        wx: form.value.value1
      },
      {
        wan: "相",
        wx: form.value.value2
      },
      // {
      //   wan: "休",
      //   wx: form.value.value3
      // }
    ],
    dsName: form.value.dsName,
    resultSsq: form.value.resultSsq,
    result3D: form.value.result3D,

  } as NyWxRequestDTO;


  const res = await NyWxResultControllerService.nyWxResultUsingPost(request)
  if (res.code == 0) {
    ElMessage({
      type: "success",
      message: "输入成功"
    })
  }

}
const nywxConfig = ref<Array<NywxConfigScq>>();
const loadData = async () => {
  const res = await NyWxResultControllerService.getListUsingGet()
  if (res.code == 0) {
    nywxConfig.value = res.data
  }

}
const dsNameform = ref<any>({});
const isShow = ref<boolean>(false);
const addDsName = () => {
  isShow.value = true
}
const dsNameList = ref([]);
const getDsName = async () => {
  const res = await DsNameControllerService.getListUsingGet()
  dsNameList.value = res.data
}
const submit = async () => {
  console.log("添加", dsNameform.value)
  if (!dsNameform.value.name) {
    return ElMessage({
      type: "warning",
      message: "名字不能为空"
    })
  }
  const res = await DsNameControllerService.addUsingPost(dsNameform.value)
  if (res.code === 0) {
    ElMessage({
      type: "success",
      message: "添加成功"
    })
    isShow.value = false
  }
  dsNameform.value = {};
  await getDsName();
}
const Cancel = () => {
  isShow.value = false;
  dsNameform.value = {};
}

onMounted(() => {
  loadData();
  getDsName()
})
</script>

<style scoped>

</style>