/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_Page_Wx_ } from '../models/BaseResponse_Page_Wx_';
import type { BaseResponse_Void_ } from '../models/BaseResponse_Void_';
import type { BaseResponse_Wx_ } from '../models/BaseResponse_Wx_';
import type { Wx } from '../models/Wx';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WxSsqControllerService {

    /**
     * add
     * @param wx wx
     * @returns BaseResponse_Void_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addUsingPost(
        wx: Wx,
    ): CancelablePromise<BaseResponse_Void_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ssqView',
            body: wx,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * update
     * @param wx wx
     * @returns BaseResponse_Void_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateUsingPut(
        wx: Wx,
    ): CancelablePromise<BaseResponse_Void_ | any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/ssqView',
            body: wx,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getList
     * @param current
     * @param endDate
     * @param id
     * @param name
     * @param pageSize
     * @param sortField
     * @param sortOrder
     * @param startDate
     * @param wxData
     * @returns BaseResponse_Page_Wx_ OK
     * @throws ApiError
     */
    public static getListUsingGet(object:any): CancelablePromise<BaseResponse_Page_Wx_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ssqView/list',
            query: {
                'current': object.current,
                'endDate': object.endDate,
                'id': object.id,
                'name': object.name,
                'pageSize': object.pageSize,
                'sortField': object.sortField,
                'sortOrder': object.sortOrder,
                'startDate': object.startDate,
                'wxData': object.wxData,
                'showNumber':  object.showNumber,
                'isSave':object.isSave
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getInfo
     * @param id id
     * @returns BaseResponse_Wx_ OK
     * @throws ApiError
     */
    public static getInfoUsingGet(
        id: string,
    ): CancelablePromise<BaseResponse_Wx_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ssqView/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * del
     * @param id id
     * @returns BaseResponse_Void_ OK
     * @throws ApiError
     */
    public static delUsingDelete(
        id: string,
    ): CancelablePromise<BaseResponse_Void_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/ssqView/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
