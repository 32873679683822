<template>
  <div class="container">
    <H2>河洛预测后台系统</H2>
    <div style="display: flex; justify-content: center; align-items: center; height: 700px; width: 100%;">

      <el-card style="display: flex;width: 480px; justify-content: center; align-items: center;">
        <el-form :model="form" label-width="auto" style="max-width: 600px; margin: 0 auto">
          <el-form-item label="账号" prop="pass">
            <el-input v-model="form.userAccount"  autocomplete="off" />
          </el-form-item>
          <el-form-item label="密码" prop="checkPass">
            <el-input
                v-model="form.userPassword"
                type="password"
                autocomplete="off"
            />
          </el-form-item>
        </el-form>
      </el-card>

    </div>
    <el-button type="success" @click="login"> 登录 </el-button>

  </div>
</template>
<script setup lang="ts">
import {ref} from "vue";
import {UserControllerService, type UserLoginRequest} from "../../generated";
import store from "@/store";
import {useRouter} from "vue-router";
import {ElMessage} from "element-plus";
const router = useRouter();

const form = ref<UserLoginRequest>({

});
const login = async () => {
 const res = await UserControllerService.userLoginUsingPost(form.value)
  console.log(res)
  console.log(res.code === 0)
  if(res.code === 0){
    ElMessage({
      type: "success",
      message: "登录成功"
    })
   const userInfo = res.data
    store.dispatch("storeUserInfo",{userInfo})
    console.log("获取vuex",store.getters.getUserInfo)
    router.push({
      path: "/",
    });
  }else{
    ElMessage({
      type: "error",
      message: res.message
    })
  }
}
</script>
<style scoped>

</style>