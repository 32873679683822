<script setup lang="ts">

import routers from '@/router/index';
import {ref} from "vue";
import {useRouter} from "vue-router";

const router = useRouter();

const showNav = routers.options.routes.filter((item)=>{
  return !item.meta
})
const navList = ref<any>(showNav);
const handleOpen = (key: string, keyPath: string[]) => {
  console.log("handleOpen", key, keyPath)
}
const handleClose = (key: string, keyPath: string[]) => {
  console.log("handleClose", key, keyPath)
}
const handleSelect = (index: number, item: any, indexPath: any) => {
  console.log("handleSelect", index, item, indexPath)
}
const handleNavClick = (item: any) => {
  console.log("导航栏被点击了", item)
  router.push({
    path: item.path,
  });
}

</script>

<template>
  <el-menu
      default-active="0"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      mode="horizontal"
      @select="handleSelect"
  >
    <el-menu-item :index="String(index)" :key="item.path" v-for="(item,index) in navList "
                  @click="handleNavClick(item)">
      <!--          <el-icon><icon-menu /></el-icon>-->
      <span>{{ item.name }}</span>
    </el-menu-item>
    <!--    <el-sub-menu index="1">-->
    <!--      <template #title>-->
    <!--        <el-icon><location /></el-icon>-->
    <!--        <span>Navigator One</span>-->
    <!--      </template>-->
    <!--      <el-menu-item-group title="Group One">-->
    <!--        <el-menu-item index="1-1">item one</el-menu-item>-->
    <!--        <el-menu-item index="1-2">item one</el-menu-item>-->
    <!--      </el-menu-item-group>-->
    <!--      <el-menu-item-group title="Group Two">-->
    <!--        <el-menu-item index="1-3">item three</el-menu-item>-->
    <!--      </el-menu-item-group>-->
    <!--      <el-sub-menu index="1-4">-->
    <!--        <template #title>item four</template>-->
    <!--        <el-menu-item index="1-4-1">item one</el-menu-item>-->
    <!--      </el-sub-menu>-->
    <!--    </el-sub-menu>-->
    <!--    <el-menu-item index="2">-->
    <!--      <el-icon><icon-menu /></el-icon>-->
    <!--      <span>Navigator Two</span>-->
    <!--    </el-menu-item>-->
    <!--    <el-menu-item index="3" disabled>-->
    <!--      <el-icon><document /></el-icon>-->
    <!--      <span>Navigator Three</span>-->
    <!--    </el-menu-item>-->
    <!--    <el-menu-item index="4">-->
    <!--      <el-icon><setting /></el-icon>-->
    <!--      <span>Navigator Four</span>-->
    <!--    </el-menu-item>-->
  </el-menu>
</template>

<style scoped>

</style>
