<template>
  <div class="home">
    <!--    全局搜索区-->
    <el-card shadow="hover">
      <el-row>
        <el-col :span="6">
          <el-button type="primary" @click="handleAdd()">添加</el-button>
        </el-col>
        <el-col :span="6">
          <div class="block">
            <el-date-picker
                v-model="queryData.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                size="large"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
            />
          </div>
          <div class="grid-content ep-bg-purple-light"/>
        </el-col>
        <el-col :span="6">
          <span>结果显示个数</span>
          <el-input-number v-model="queryData.showNumber"></el-input-number>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" :icon="CirclePlusFilled" @click="getSubmit()">保存数据</el-button>
          <div class="grid-content ep-bg-purple"/>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" :icon="Search" @click="getList()">搜索</el-button>
          <div class="grid-content ep-bg-purple"/>
        </el-col>
      </el-row>
    </el-card>

    <!--表格区-->
    <el-table :data="tableData" border style="width: 100%">
      <!--      <el-table-column prop="id" label="id" width="180" v-show="false"/>-->
      <el-table-column prop="name" label="五行" width="180"/>
      <el-table-column prop="wxDate" label="日期"/>
      <el-table-column prop="wxData" label="号码"/>
      <el-table-column prop="sult" label="结果"/>
      <el-table-column label="操作" width="120">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="handleUpdate(scope.row)"
          >修改
          </el-button
          >
          <el-button link type="primary" size="small" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>


    <!--悬浮添加按钮-->

    <el-affix :offset="120" z-index="100">
      <el-button type="primary" @click="handleAdd()">添加</el-button>
    </el-affix>
    <!--分页插件-->
    <el-pagination
        v-model:currentPage="queryData.current"
        v-model:page-size="queryData.pageSize"
        :page-sizes="[15, 20, 50, 200]"
        :small="true"
        :background="true"
        layout="sizes, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />

    <!--对话框-->
    <el-dialog v-model="dialogFormVisible" title="添加">
      <el-form :model="form">
        <el-form-item label="五行">
          <el-select v-model="form.name" class="m-2" placeholder="五行名称" size="large">
            <el-option label="金" value="金"/>
            <el-option label="木" value="木"/>
            <el-option label="水" value="水"/>
            <el-option label="火" value="火"/>
            <el-option label="土" value="土"/>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
              v-model="form.wxDate"
              type="date"
              placeholder="日期"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="号码">
          <el-input v-model="form.wxData" placeholder="中奖号码"/>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancel()">取消</el-button>
        <el-button type="primary" @click="handleSubmit()">确定</el-button>
      </span>
      </template>
    </el-dialog>


  </div>
</template>

<script lang="ts" setup>
import {onMounted, ref} from "vue";
import {Wx, WxControllerService} from "../../generated";
import {Search,CirclePlusFilled} from '@element-plus/icons-vue'
import {ElMessage} from 'element-plus'

const dialogFormVisible = ref<boolean>(false);
const form = ref<Wx>({});
const queryData = ref<any>({
  current: 1,
  pageSize: 15,
  showNumber: 4,
  isSave: false


});
const total = ref<string | number>(0);
//分页大小改变
const handleSizeChange = (item: any) => {
  console.log("分页大小改变", item)
  queryData.value.pageSize = item;
  getList();
}
//当前页改变
const handleCurrentChange = (item: any) => {
  console.log("当前分页改变", item)
  queryData.value.current = item;
  getList();
}
const tableData = ref<Array<Wx> | undefined>([]);
//获取数据
const getList = async () => {
  if (queryData.value.time) {
    queryData.value.startDate = queryData.value.time[0];
    queryData.value.endDate = queryData.value.time[1];
  }
  const res = await WxControllerService.getListUsingGet(queryData.value)
  tableData.value = res.data?.records

  total.value = Number(res.data?.total)
  queryData.value.isSave = false;
}
const getSubmit= async ()=>{

  queryData.value.isSave = true;
  await getList();
}
// 修改操作
const handleUpdateObj = async (Obj: Wx) => {
  const res = await WxControllerService.updateUsingPut(Obj)
  if (res.code == 0) {
    if (res.code == 0) {
      ElMessage({
        type: "success",
        message: "修改成功"
      })
      dialogFormVisible.value = false;
      await getList();
    } else {
      ElMessage({
        type: "error",
        message: res.message
      })
    }
  }

}
// 添加操作
const handleAddObj = async (Obj: Wx) => {
  const res = await WxControllerService.addUsingPost(Obj)
  if (res.code == 0) {
    ElMessage({
      type: "success",
      message: "添加成功"
    })
    dialogFormVisible.value = false;
    await getList();
  } else {
    ElMessage({
      type: "error",
      message: res.message
    })
  }
}
//删除操作
const handleDelete = async (id: string) => {
  const res = await WxControllerService.delUsingDelete(id)
  if (res.code == 0) {
    ElMessage({
      type: "success",
      message: "删除成功"
    })
    await getList();
  } else {
    ElMessage({
      type: "error",
      message: res.message
    })
  }

}
//修改按钮
const handleUpdate = (item: Wx) => {
  form.value = {...item}
  dialogFormVisible.value = true;
}
//添加按钮
const handleAdd = () => {
  form.value = {};
  dialogFormVisible.value = true
}
//对话框取消按钮
const handleCancel = () => {
  form.value = {};
  dialogFormVisible.value = false;
}
//对话框确定/提交按钮
const handleSubmit = () => {
  if (form.value.name == null || form.value.name.length == 0) {
    return ElMessage.error("五行不能为空")
  }
  if (form.value.wxData == null || form.value.wxData.length == 0) {
    return ElMessage.error("数据不能为空")
  }
  if (form.value.wxDate == null || form.value.wxDate.length == 0) {
    return ElMessage.error("日期不能为空")
  }
  let trim = form.value.wxData?.trim();
  let replace = trim?.replaceAll(/，/g, ',');
  form.value.wxData = replace
  if (form.value.id) {
    handleUpdateObj(form.value);
  } else {
    handleAddObj(form.value);
  }
}
onMounted(() => {
  getList();
})
</script>
